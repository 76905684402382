#Directory {
    h1 {
        font-family: cursive;
        margin-top: 15px;
        font-size: 50px;
    }

    h6 {
        font-family: fantasy;
        margin-top: 30px;
    }

    #col-names {
        margin-top: 50px;
        text-decoration: underline;
    }

    // button {
    //     width: auto;
    //     background-color: rgba(189, 242, 242, 0.307);
    //     margin-top: 30px;
    // }

    a {
        color: black;
        text-decoration: none;
    }

    .table {
        margin-top: 50px;
    }
}
