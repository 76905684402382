#adopterSignin {
    link {
        color: black;
        text-decoration: none;
    }

    // container-fluid {
    //     color: red;
    // }

    /* #signinContainer {
    height: 600px;
    width: 500px;
    background-color: blue;
    justify-content: center;
} */

    /* #username {
    margin-left: 200px;
} */

    form {
        width: 500px;
        height: 600px;
        background-color: rgb(243, 243, 247);
        margin: auto;
        margin-top: 100px;
        border-radius: 25px;
        text-align: center;
        opacity: 75%;
        font-family: fantasy;
        font-size: larger;
    }

    #submitButton {
        margin-top: 25px;
        text-align: center;
    }

    h5,
    h6 {
        margin-top: 20px;
        text-align: center;
    }

    /* #usernameText {
    margin-top: 25px;
    text-align: center;
}

#passwordText {
    margin-top: 25px;
} */

    label {
        margin-top: 25px;
        text-align: center;
        margin-left: 25px;
    }

    input {
        text-align: center;
    }

    h2 {
        padding-top: 25px;
        font-family: fantasy;
    }
}
