#addAPet {
    a {
        color: black;
        text-decoration: none;
    }

    h1 {
        font-family: cursive;
        margin-top: 15px;
        font-size: 50px;
        padding-bottom: 25px;
    }

    #header {
        color: rgb(30, 173, 156);
        font-size: 50px;
        font-family: cursive;
    }

    /* Style inputs, select elements and textareas */
    input[type="text"],
    select,
    textarea {
        width: 100%;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        resize: vertical;
    }

    /* Style the label to display next to the inputs */
    label {
        padding: 12px 12px 12px 0;
        display: inline-block;
    }

    /* Style the submit button */
    input[type="submit"] {
        background-color: #04aa6d;
        color: white;
        padding: 12px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        float: right;
    }

    /* Style the container */
    .container {
        border-radius: 5px;
        background-color: #f2f2f2;
        padding: 20px;
    }

    /* Floating column for labels: 25% width */
    .col-25 {
        float: left;
        width: 25%;
        margin-top: 6px;
    }

    /* Floating column for inputs: 75% width */
    .col-75 {
        float: left;
        width: 75%;
        margin-top: 6px;
    }

    /* Clear floats after the columns */
    .row:after {
        content: "";
        display: table;
        clear: both;
    }

    /* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
    @media screen and (max-width: 600px) {
        .col-25,
        .col-75,
        input[type="submit"] {
            width: 50%;
            margin-top: 0;
        }
    }

    a {
        color: black;
        text-decoration: none;
    }

    #submitButton {
        width: 200px;
        height: 50px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
        font-family: segoe ui;
        font-size: 17px;
    }

    #required {
        padding-top: 25px;
        text-align: left;
    }

    h6 {
        text-align: right;
        margin-right: 35px;
        font-family: fantasy;
        margin-top: 15px;
    }

    .resize-words {
        font-size: 12px; /* Change the size to your desired value */
    }

    #photoButton {
    }
}
