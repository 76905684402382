a {
    color: black; //like my soul
    text-decoration: none; //also like my soul
}

h1 {
    color: black;
    font-family: cursive;
    font-size: 50px;
    margin-top: 15px;
}

body {
    background: linear-gradient(
        222deg,
        #ffadad,
        #ffd6a5,
        #fdffb6,
        #caffbf,
        #9bf6ff,
        #a0c4ff,
        #bdb2ff,
        #ffc6ff,
        #fffffc
    );
    min-height: 100vh;
}
