#ShelterProfilePage {
    h6 {
        text-align: right;
        margin-right: 35px;
        font-family: fantasy;
        margin-top: 15px;
    }

    h4 {
        color: black;
        font-family: cursive;
        margin-top: 15px;
    }

    h1 {
        text-align: center;
    }

    h5 {
        margin-top: 50px;
        font-family: segoe ui;
    }

    // p {
    //     margin-top: 50px;
    //     font-family: segoe ui;
    // }

    #EditProfile {
        margin-top: 25px;
    }

    #Tully {
        padding-left: 75px;
    }
}
