#AdopterProfilePage {
    a {
        color: black;
        text-decoration: none;
    }

    h3 {
        font-family: cursive;
        margin-top: 15px;
        font-size: 25px;
    }
}
